import StatistiсListItem from "../statisticListItem/StatisticListItem";

import diagram_1 from "../../assets/diagram_1.png";
import diagram_2 from "../../assets/diagram_2.png";
import diagram_3 from "../../assets/diagram_3.png";

import { ClipLoader } from "react-spinners";
import { useGetSalesStatQuery } from "../../api/orders";
import "./dashboardStatisticList.scss";

const DashboardStatistiсList = () => {
  const { data: salesStat = {}, isLoading, isError } = useGetSalesStatQuery();
  const {daily_amount, daily_sales, weekly_amount, weekly_sales, total_amount, total_sales} = salesStat 

  const spinner = (
    <ClipLoader
      className="spinner"
      color={"#9760ad"}
      loading={true}
      size={45}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );

  const errorMsg = "Произошла ошибка"

  return (
    <ul className="dashboard__statistic-list">
      <StatistiсListItem
        key={1}
        title={"За день"}
        subTitle={isLoading ? spinner : isError ? errorMsg : `${daily_amount}$`}
        desciption={isLoading ? spinner : isError ? errorMsg : `Продаж: ${daily_sales} шт.`}
        imgSrc={diagram_1}
      />
      <StatistiсListItem
        key={2}
        title={"За неделю"}
        subTitle={isLoading ? spinner : isError ? errorMsg : `${weekly_amount}$`}
        desciption={isLoading ? spinner : isError ? errorMsg : `Продаж: ${weekly_sales} шт.`}
        imgSrc={diagram_2}
      />
      <StatistiсListItem
        key={3}
        title={"За все время"}
        subTitle={isLoading ? spinner : isError ? errorMsg : `${total_amount}$`}
        desciption={isLoading ? spinner : isError ? errorMsg : `Продаж: ${total_sales} шт.`}
        imgSrc={diagram_3}
      />
    </ul>
  );
};

export default DashboardStatistiсList;
